@import ./variables

*
  box-sizing: border-box

body
  margin: 0
  padding: 0
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.App
  min-height: 100vh
  display: flex
  flex-direction: column

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

h3, h4
  margin-bottom: -7px

.btn
  background-color: $base-color
  color: white
  border-radius: 50px
  padding: 15px 20px
  letter-spacing: 1px
  font-weight: bold
  text-transform: capitalize
  text-decoration: none
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.2)
  transition: 0.3s

  &:hover
    background-color: lighten($base-color, 5%)

  &:active
    background-color: darken($base-color, 5%)

li::before
  font-family: inherit

#contactBtn
  display: inline-block
  margin-top: 30px

section.default-page
  .content
    max-width: 1100px
    margin: 0 auto
    padding: 80px

    article
      padding: 5px 0

    ul
      color: #444
      list-style-type: none

      li
        padding: 4px

      li::before
        content: url(../public/svg/checkmark.svg)
        margin-right: 7px
        display: inline-block
        transform: translateY(2px)
        color: $base-color

    ul.main-ul
      li::before
        content: url(../public/svg/arrow-right.svg)

    @media (max-width: 720px)
      ul
        padding-left: 20px

    @media (max-width: 500px)
      padding: 50px

      ul
        padding-left: 0

.text
  text-align: left
  line-height: 1.5
  color: $text-color
