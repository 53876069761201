#header {
  background-color: white;
}
#header .header-elements {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
#header .logo {
  padding: 20px;
}
#header .logo img {
  height: 60px;
}
#header .menu-button {
  margin-right: 30px;
  padding: 0;
  width: -moz-min-content;
  width: min-content;
  height: 20px;
  display: none;
}
#header .menu-button:hover {
  cursor: pointer;
}
#header .menu-button .bar {
  background-color: #444;
  width: 20px;
  height: 3px;
  border-radius: 3px;
  transition: all 0.2s linear;
}
#header .menu-button .bar:not(:first-child) {
  margin-top: 5px;
}
#header .menu-button:hover .bar {
  background-color: #3EB489;
}
#header .menu-button .crossed:nth-child(1) {
  transform: rotate(-45deg);
  margin-top: 8px;
}
#header .menu-button .crossed:nth-child(2) {
  opacity: 0;
}
#header .menu-button .crossed:nth-child(3) {
  transform: rotate(45deg);
  margin-top: -11px;
}
#header ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  padding: 0;
  margin: 0;
  background-color: white;
}
#header ul li {
  text-transform: uppercase;
  padding: 30px;
  color: #444;
}
#header ul li a {
  text-decoration: none;
  color: #444;
  transition: 0.2s linear;
}
#header ul li a:hover {
  color: #3EB489;
}
#header .dropdown-menu {
  visibility: hidden;
  opacity: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 90px;
  left: 0;
  right: 0;
  border-radius: 10px;
  background-color: #eee;
  overflow: hidden;
  transition: all 0.2s cubic-bezier(0.16, 1, 0.5, 1);
  transform: translateY(0.5rem);
}
#header .dropdown-menu li {
  width: 100%;
  font-size: 0.8rem;
  padding: 0;
  text-align: left;
}
#header .dropdown-menu li a {
  display: inline-block;
  width: 100%;
  padding: 10px 20px;
}
#header .dropdown-menu li a:hover {
  color: white;
  background-color: #3EB489;
}
@media (max-width: 1050px) {
  #header .menu-button {
    display: inline-block;
  }
  #header .regular-menu {
    display: none;
  }
  #header .unfolded {
    visibility: visible;
    opacity: 1;
    transform: translateY(0rem);
  }
}
@media (max-width: 500px) {
  #header .logo img {
    height: 40px;
  }
  #header .menu-button {
    font-size: 1.5rem;
  }
  #header .dropdown-menu {
    top: 70px;
  }
}
@media (max-width: 300px) {
  #header .header-elements {
    flex-direction: column;
  }
  #header .menu-button {
    margin: 5px;
  }
  #header .dropdown-menu {
    top: 115px;
  }
}/*# sourceMappingURL=Header.css.map */