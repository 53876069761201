* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h3, h4 {
  margin-bottom: -7px;
}

.btn {
  background-color: #3EB489;
  color: white;
  border-radius: 50px;
  padding: 15px 20px;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: capitalize;
  text-decoration: none;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.btn:hover {
  background-color: #4bc196;
}
.btn:active {
  background-color: #37a17b;
}

li::before {
  font-family: inherit;
}

#contactBtn {
  display: inline-block;
  margin-top: 30px;
}

section.default-page .content {
  max-width: 1100px;
  margin: 0 auto;
  padding: 80px;
}
section.default-page .content article {
  padding: 5px 0;
}
section.default-page .content ul {
  color: #444;
  list-style-type: none;
}
section.default-page .content ul li {
  padding: 4px;
}
section.default-page .content ul li::before {
  content: url(../public/svg/checkmark.svg);
  margin-right: 7px;
  display: inline-block;
  transform: translateY(2px);
  color: #3EB489;
}
section.default-page .content ul.main-ul li::before {
  content: url(../public/svg/arrow-right.svg);
}
@media (max-width: 720px) {
  section.default-page .content ul {
    padding-left: 20px;
  }
}
@media (max-width: 500px) {
  section.default-page .content {
    padding: 50px;
  }
  section.default-page .content ul {
    padding-left: 0;
  }
}

.text {
  text-align: left;
  line-height: 1.5;
  color: #444;
}/*# sourceMappingURL=index.css.map */