.menu-tiles {
  padding: 50px 20px;
  margin: 0 auto;
}
.menu-tiles ul {
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 0;
}
.menu-tiles ul li {
  border-radius: 10px;
  margin: 20px;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.3);
  width: 150px;
  height: 150px;
}
.menu-tiles ul li:nth-child(2) {
  transition-delay: 200ms !important;
}
.menu-tiles ul li:nth-child(3) {
  transition-delay: 400ms !important;
}
.menu-tiles ul li:nth-child(4) {
  transition-delay: 600ms !important;
}
.menu-tiles ul li:hover a {
  color: #3EB489;
}
.menu-tiles ul li a {
  padding: 30px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.7294117647);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  font-size: 1.5rem;
  transition: 0.2s linear;
  width: 100%;
  height: 100%;
}
.menu-tiles ul li a svg {
  font-size: 3.5rem;
}
@media (max-width: 800px) {
  .menu-tiles {
    max-width: 600px;
  }
}/*# sourceMappingURL=MenuTiles.css.map */