@import "../../variables"

.paragraph
    padding: 100px
    max-width: 1000px
    margin: 0 auto
    line-height: 1.5
    color: $text-color

    @media (max-width: 720px)
        padding: 80px

    @media (max-width: 500px)
        padding: 50px
