@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap')

.static-background-photo
    height: 350px
    overflow: hidden
    background-attachment: fixed
    background-position: center
    background-repeat: no-repeat
    background-size: cover
    color: white
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column

    span
        width: 80%
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5)
        font-family: 'Quicksand', sans-serif

    .quote
        font-weight: bold
        font-size: 2.6rem
        letter-spacing: 2px
        font-style: italic

    .author
        text-align: right
        font-size: 1.5rem
        padding: 0 150px

    @media (max-width: 625px)
        .quote
            font-size: 2rem
        .author
            font-size: 1rem
            padding: 0 50px

    @media (max-width: 500px)
        background-attachment: local

    @media (max-width: 400px)
        .quote
            font-size: 1.8rem
        .author
            padding: 0 15px
