@import "../../variables"

.menu-tiles
    padding: 50px 20px
    margin: 0 auto

    ul
        list-style-type: none
        margin: 0
        display: flex
        flex-flow: row wrap
        justify-content: center
        padding: 0

        li
            border-radius: 10px
            margin: 20px
            box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.3)
            width: 150px
            height: 150px

            &:nth-child(2)
                transition-delay: 200ms !important

            &:nth-child(3)
                transition-delay: 400ms !important

            &:nth-child(4)
                transition-delay: 600ms !important

            &:hover
                a
                    color: $base-color

            a
                padding: 30px
                text-decoration: none
                color: #000000ba
                display: flex
                flex-flow: column nowrap
                align-items: center
                font-size: 1.5rem
                transition: .2s linear
                width: 100%
                height: 100%

                svg
                    font-size: 3.5rem

    @media (max-width: 800px)
        max-width: 600px
