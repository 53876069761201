@import "../../variables"

.contact-page
    .contact
        margin: 0 auto
        max-width: 1200px
        padding: 50px
        color: #333
        display: flex
        flex-direction: column
        align-items: center

        .text
            text-align: center
            max-width: 800px

        ul
            list-style-type: none
            display: flex
            flex-flow: row wrap
            justify-content: center
            align-items: stretch
            padding: 0

            li
                border-radius: 10px
                margin: 20px
                box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.3)
                width: 200px
                text-decoration: none
                color: #000000ba
                display: flex
                flex-flow: column nowrap
                align-items: center
                justify-content: center

                &:last-child
                    padding: 30px

                a
                    text-decoration: none
                    color: #000000ba
                    display: flex
                    flex-flow: column nowrap
                    align-items: center
                    justify-content: center
                    width: 100%
                    height: 100%
                    padding: 30px

                span
                    transition: .2s linear
                    font-size: .95rem
                    padding: 2px 0

                .type
                    font-size: 1.2rem
                    font-weight: bold

                svg
                    color: $base-color
                    font-size: 3.5rem
                    transition: .2s linear
                    margin-bottom: 15px

                &:nth-child(2)
                    transition-delay: 200ms !important

                &:nth-child(3)
                    transition-delay: 400ms !important

    .map
        margin-bottom: 70px
