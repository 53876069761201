footer {
  background-color: #3EB489;
  color: white;
  font-weight: 300;
  letter-spacing: 0.5px;
  font-size: 0.9rem;
  padding: 15px 15%;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  margin: 0 -10%;
}

.footer-wrapper {
  margin: 0;
  margin-top: auto;
  overflow: hidden;
}/*# sourceMappingURL=Footer.css.map */