@import ../../variables

#header
    background-color: white

    .header-elements
        max-width: 1200px
        margin-left: auto
        margin-right: auto
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center
        position: relative

    .logo
        padding: 20px

        img
            height: 60px

    .menu-button
        margin-right: 30px
        padding: 0
        width: min-content
        height: 20px
        display: none

        &:hover
            cursor: pointer

        .bar
            background-color: $text-color
            width: 20px
            height: 3px
            border-radius: 3px
            transition: all .2s linear

        .bar:not(:first-child)
            margin-top: 5px

        &:hover .bar
            background-color: $base-color

        .crossed
            &:nth-child(1)
                transform: rotate(-45deg)
                margin-top: 8px
            &:nth-child(2)
                opacity: 0
            &:nth-child(3)
                transform: rotate(45deg)
                margin-top: -11px

    ul
        list-style-type: none
        display: flex
        align-items: center
        flex-flow: row wrap
        padding: 0
        margin: 0
        background-color: white

        li
            text-transform: uppercase
            padding: 30px
            color: $text-color

            a
                text-decoration: none
                color: $text-color
                transition: .2s linear

                &:hover
                    color: $base-color

    .dropdown-menu
        visibility: hidden
        opacity: 0
        display: flex
        flex-direction: column
        position: absolute
        top: 90px
        left: 0
        right: 0
        border-radius: 10px
        background-color: #eee
        overflow: hidden
        transition: all .2s cubic-bezier(0.16, 1, 0.5, 1)
        transform: translateY(0.5rem)

        li
            width: 100%
            font-size: .8rem
            padding: 0
            text-align: left

            a
                display: inline-block
                width: 100%
                padding: 10px 20px

                &:hover
                    color: white
                    background-color: $base-color

    @media (max-width: 1050px)
        .menu-button
            display: inline-block

        .regular-menu
            display: none

        .unfolded
            visibility: visible
            opacity: 1
            transform: translateY(0rem)

    @media (max-width: 500px)
        .logo img
            height: 40px

        .menu-button
            font-size: 1.5rem

        .dropdown-menu
            top: 70px

    @media (max-width: 300px)
        .header-elements
            flex-direction: column

        .menu-button
            margin: 5px

        .dropdown-menu
            top: 115px
