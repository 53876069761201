@import "../../variables"

.about-me-page
    .about-me
        img
            border-radius: 20px
            width: 100%

        img.large
            width: 35%
            margin: 0 35px 20px
            float: right

        .photos
            display: flex
            gap: 10px
            padding: 15px 0

        @media (max-width: 720px)
            img.large
                border-radius: 10px
                margin: 30px

        @media (max-width: 500px)
            img.large
                width: 80%
                float: none
                margin: 20px auto

            .photos
                flex-direction: column
