.about-me-page .about-me img {
  border-radius: 20px;
  width: 100%;
}
.about-me-page .about-me img.large {
  width: 35%;
  margin: 0 35px 20px;
  float: right;
}
.about-me-page .about-me .photos {
  display: flex;
  gap: 10px;
  padding: 15px 0;
}
@media (max-width: 720px) {
  .about-me-page .about-me img.large {
    border-radius: 10px;
    margin: 30px;
  }
}
@media (max-width: 500px) {
  .about-me-page .about-me img.large {
    width: 80%;
    float: none;
    margin: 20px auto;
  }
  .about-me-page .about-me .photos {
    flex-direction: column;
  }
}/*# sourceMappingURL=AboutMe.css.map */