@import "../../variables"
.page-not-found
    color: $base-color
    font-size: 2rem
    flex-grow: 1
    display: flex
    flex-flow: column wrap
    align-items: center
    justify-content: center

    .error-message
        font-weight: bold

    span
        width: 50%
        margin: 5px
